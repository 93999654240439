import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { baseUrl } from "../Constants/Constants";
import { Grid, Box, CircularProgress } from "@mui/material";
import ProductCard from "../ProductCard/ProductCard";
import axios from "axios";

function AllProducts() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    await axios
      .get(baseUrl + "/get_products")
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.data.response.message);
        setLoading(false);
      });
  };
  return (
    <>
      <Helmet>
        <title>Ooty Crops | Fresh Ooty Vegetables</title>
        <meta
          name="description"
          content="Fresh Exotic vegetables from Ooty | Broccoli | Lettuce | chinese cabbage | Zucchini | Pok choy | Asparagus"
        />
      </Helmet>

      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span className="text-danger">Error </span>: {error.message}
        </Box>
      )}

      <p
        className="my-4 text-success text-center h3"
        style={{ fontSize: "24px" }}
      >
        All Products
      </p>

      <Grid container spacing={2}>
        {products.length > 0
          ? products.map((item) => (
              <Grid item xs={6} sm={4} md={2.4} key={item.id}>
                <ProductCard product={item} />
              </Grid>
            ))
          : null}
      </Grid>
    </>
  );
}

export default AllProducts;
