import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "./ImageSlider.css";

// Import Swiper styles
import "swiper/css";

import { Autoplay } from "swiper/modules";

function ImageSlider() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img
            src="https://img.buzzfeed.com/buzzfeed-static/static/2019-03/6/11/enhanced/buzzfeed-prod-web-05/original-4104-1551889017-2.jpg"
            alt="veg"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src="https://www.allthatgrows.in/cdn/shop/articles/Feat_image-Exotic_1100x1100.png?v=1654490717"
            alt="veg"
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default ImageSlider;
