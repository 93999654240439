import React from "react";
import { Helmet } from "react-helmet-async";
function AboutUs() {
  return (
    <div>
      <Helmet>
        <title>
          Ooty Crops: Your Trusted Partner for Fresh Exotic Ooty Vegetables
        </title>
        <meta
          name="description"
          content="We are Ooty Crops, dedicated to providing the freshest wholesale vegetables from the Ooty hills. Learn about our passion for quality and our commitment to our customers."
        />
      </Helmet>
      <p className="h1 my-5 text-center fw-bold">About Us - Ooty Crops</p>
      <div className="h3 fw-bold">Our Story: A Decade of Freshness</div>
      <p>
        Ooty Crops is more than just a business; it's a passion project rooted
        in the heart of India's pristine Nilgiri hills. Our journey began over a
        decade ago with a simple yet ambitious vision: to bring the unparalleled
        freshness of Ooty's produce directly to the doorsteps of businesses
        across the country.
      </p>
      <p>
        With unwavering dedication and a deep-rooted love for agriculture, we
        embarked on a mission to cultivate strong relationships with local
        farmers.
      </p>
      <p>
        Our commitment to quality and sustainability has been the cornerstone of
        our growth, allowing us to build a loyal customer base of over 1000
        satisfied clients. From humble beginnings, Ooty Crops has blossomed into
        a trusted name in the wholesale vegetable industry. Our success story is
        a testament to the hard work and expertise of our team, coupled with the
        unparalleled richness of Ooty's natural resources.
      </p>
      <div className="h3 fw-bold mt-5">
        Our Commitment to Quality and Sustainability
      </div>
      <p>
        At Ooty Crops, our commitment to delivering the freshest and highest
        quality vegetables is unwavering. We believe that exceptional produce
        starts with nurturing the land and respecting the environment. That's
        why we have forged strong partnerships with local farmers who share our
        passion for sustainable agriculture.
      </p>
      <p>
        By working closely with our farming communities, we ensure that our
        vegetables are cultivated using organic practices and minimal chemical
        interventions. This commitment not only safeguards the environment but
        also guarantees the purity and natural goodness of our produce.
      </p>
      <p>
        Our rigorous quality control measures extend beyond the farms. We employ
        state-of-the-art sorting and packaging facilities to preserve the
        freshness and integrity of our vegetables. Our dedicated team of experts
        meticulously inspects every batch to meet our stringent quality
        standards.
      </p>
      <div className="h3 fw-bold mt-5"> Partnering for Success</div>
      <p>
        We understand the unique needs of our customers, whether you're a large
        supermarket chain, a bustling restaurant, or a local distributor. Our
        customer-centric approach ensures that we tailor our offerings to meet
        your specific requirements.
      </p>{" "}
      <p>
        We pride ourselves on building long-lasting relationships with our
        clients, based on trust, reliability, and mutual growth. Our commitment
        to exceptional service, coupled with our unwavering dedication to
        quality, sets us apart as a preferred partner in the wholesale vegetable
        industry.
      </p>
    </div>
  );
}

export default AboutUs;
