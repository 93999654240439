import "./App.css";
import { createTheme, ThemeProvider, Toolbar } from "@mui/material";
import NavigationBar from "./NavigationBar/NavigationBar";
import { green } from "@mui/material/colors";
import { Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import Category from "./Categories/Category";
import AllProducts from "./AllProducts/AllProducts";
import AboutUs from "./AboutUs/AboutUs";
import { HelmetProvider } from "react-helmet-async";
const theme = createTheme({
  palette: {
    primary: {
      main: green[600],
      contrastText: "#fff",
    },
  },
});

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <div className="container">
          <NavigationBar />
          <Toolbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/category" element={<Category />} />
            <Route path="/allProducts" element={<AllProducts />} />
            <Route path="/about" element={<AboutUs />} />
          </Routes>
        </div>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
