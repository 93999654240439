import React from "react";
import { Card, CardMedia, CardContent, Box, Typography } from "@mui/material";
import { imageUrl } from "../Constants/Constants";
import "./ProductCard.css";
import { motion } from "framer-motion";

function ProductCard(props) {
  const { product } = props;
  return (
    <motion.div
      initial={{
        opacity: 0,
        x: -100,
      }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: {
          ease: "easeIn",
          delay: 0.2,
        },
      }}
      viewport={{ once: true }}
    >
      <Card>
        <CardMedia
          component="img"
          image={imageUrl + product.image}
          sx={{
            height: 180,
            "@media (min-width: 769px)": {
              height: 200,
            },
          }}
          className="product-image"
          alt={product.product_name}
        />
        <CardContent>
          <Box>
            <Typography sx={{ textAlign: "center" }}>
              {product.product_name}
            </Typography>
            <Typography className="text-danger text-center">
              {"₹" + product.price}/{product.unit}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </motion.div>
  );
}

export default ProductCard;
