import axios from "axios";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Grid, CircularProgress } from "@mui/material";

import { useLocation } from "react-router-dom";
import { baseUrl } from "../Constants/Constants";
import ProductCard from "../ProductCard/ProductCard";

function Category() {
  const location = useLocation();
  const id = location.state.id;
  const category = location.state.category;

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(
    () => {
      fetchProducts();
    },
    // eslint-disable-next-line
    [id]
  );

  const fetchProducts = async () => {
    await axios
      .post(baseUrl + "/category_product", {
        category_id: id,
      })
      .then((response) => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
      });
  };

  return (
    <div>
      <Helmet>
        <title>Ooty Crops | Fresh Ooty Vegetables</title>
        <meta
          name="description"
          content="Fresh Exotic and English vegetables from Ooty"
        />
      </Helmet>
      <p
        className="my-4 text-success text-center h3"
        style={{ fontSize: "24px" }}
      >
        {category}
      </p>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span className="text-danger">Error </span>: {error.message}
        </Box>
      )}
      {!error && products.length === 0 && !loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          No products found
        </Box>
      )}

      <Grid container spacing={1.5}>
        {products.length > 0 &&
          products.map((item) => (
            <Grid item xs={6} sm={4} md={2.4} key={item.id}>
              <ProductCard product={item} />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

export default Category;
